import * as React from 'react'
import { Link } from 'gatsby'

import 'react-slidedown/lib/slidedown.css'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import OfferForm from "../components/OfferForm";
import styled from "@emotion/styled";
let Scroll = require('react-scroll');
let scroller = Scroll.scroller;
var Element = Scroll.Element;
const P = styled.p`
font-family: ChaparralPro-Light, serif;
font-weight: normal;
font-size: 1.1em;
letter-spacing: 0.1em;
color: #333;
padding-bottom: 2.5em;
line-height: 1.5em;`

const Wrapper = styled.div`
.css-1genk75-StyledCarousel etki0io0{
display:none !important;
}

.cateupel{
display:none !important;
}
`


const Flex = styled.div`
width:100%;
display:flex;
justify-content:space-between;


 @media screen and (max-width: 870px) {
 display:block;
 }
`

const Img = styled.img`

height:805.98px;
margin-left:24px;


 @media screen and (max-width: 870px) {
 margin-top:6em;
width:100%;
height:auto;
margin-left:0px;
 }
`
const DesktopDiv = styled.div`
 @media screen and (max-width: 870px) {
display:none;

 }
`
const img = require('../../assets/images/slider/v7_07.jpg')

class RequestOffer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }


  componentDidMount() {
    scroller.scrollTo('requestArea', {
      duration: 1500,
      smooth: true,
    })
  }


  render() {
    return (
      <IndexLayout>
        <Page>
          <Container>
            <DesktopDiv>
              <Element name="requestArea"></Element>
            </DesktopDiv>
      <h2>Jetzt anfragen</h2>
            <br/>
            <br/>
            <br/>
            <Flex>

              <OfferForm/>
              <Img src={img}></Img>

            </Flex>

          </Container>
        </Page>
      </IndexLayout>
    )
  }
}

export default RequestOffer
